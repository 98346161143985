.layout {
    color: var(--txt-color); 
}

.layout__container {
    padding-left: var(--sidebar-width);
    
    
    
    /* background-color: var(--second-bg); */
    /* min-height: 100vh;  */
}

.layout__container-main {
    /* padding: 20px 30px; */
padding:20px 10px 20px 30px;
    min-height: 100vh;
    background-color: var(--main-bg);
}
