.sidebar {
    min-width: var(--sidebar-width);
    /* height: 100vh; */
    /* position: fixed; */
    left: 0;
    top: var(--topnav-height);
    bottom:0;
    background-color: var(--sidebar-bg);
    /* box-shadow: var(--box-shadow);
    box-shadow: var(--box-shadow) !important; */
    /* box-shadow: -3px 0 5px 0 #555 !important; */
    color: #313131 !important;
} 

 


.sidebar__item {
    padding: 0 20px;
}

.sidebar__item-inner {
    padding: 15px 25px;
    display: flex;
    align-items: center;
    font-weight: 600;
    transition: color 0.3s ease 0s;
}

.sidebar__item-inner > i {
    margin-right: 10px;
    font-size: 1.5rem;
}

.sidebar__item-inner > span {
    text-transform: capitalize;
}

.sidebar__item-inner:hover {
    color: var(--main-color); 
}

.sidebar .ant-space-item:hover {
    background-color: #E2EDF6;
}

.sidebar__item-inner.active {
    border-radius: var(--border-radius);
    background-image: linear-gradient(
        to right,
        var(--main-color),
        var(--second-color)
    );
    color: var(--txt-white);
}






.sidebar {
    background-color: var(--sidebar-bg);
    position: fixed;
    top: 14%;
    left: 0;
    width: var(--sidebar-width);
    /* height: 100vh; */
    box-shadow: var(--shadow);
    /* overflow-y: auto; */
    /* box-shadow: -3px 0 5px 0 #555 !important; */
}

.dark .sidebar {
    border-right: 2px solid var(--border-color);
}

/* .sidebar-logo {
    height: var(--sidebar-logo-size);
    position: relative;
    padding: 10px 5px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar-logo img {
    height: var(--sidebar-logo-img-size);
    max-width: 100%;
} */

.sidebar-close {
    display: none;
}

.sidebar-user {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.sidebar-user-info {
    display: flex;
    align-items: center;
}

.sidebar-user-name {
    margin-left: 10px;
    font-size: 1.25rem;
    font-weight: 700;
    text-transform: capitalize;
}

.profile-image {
    width: var(--profile-image-size);
    height: var(--profile-image-size);
    border-radius: 10px;
}

.btn {
    background-color: transparent;
    border: none;
    outline: none;
    display: grid;
    place-items: center;
    cursor: pointer;
    color: var(--main-color);
    padding: 0.5rem;
    border-radius: 0.25rem;
}

.btn i {
    font-size: 1.25rem;
}

.btn-outline {
    border: 2px solid var(--main-color);
    transition: background-color 0.2s ease-in-out;
}

.btn-outline:hover {
    background-color: var(--main-color);
    color: #fff;
}

.sidebar-menu {
    /* padding: 10px 0; */
    list-style-type: none; 
    height: 90vh;
    overflow-y: auto;
}

.sidebar-menu > li > a {
    width: 100%;
    padding: 10px;
    height: 37px;
    display: flex;
    align-items: center;
    font-size: 1rem;
    font-weight: 600;
    text-transform: capitalize;
    transition: color 0.2s ease-in-out;
    font-size: 15px;
}

.sidebar-menu > li > a.active {
    background-color: var(--menu-item-active-bg);
    color: var(--main-color);
    border-left: 4px solid var(--active-border-color);
}

.sidebar-menu > li > a:hover { 
    color:var(--main-color);
} 

.sidebar-menu > li > a > i {
    font-size: 1.5rem;
    margin-right: 10px;
}

.sidebar-menu-dropdown {
    position: relative;
}

.sidebar-menu-dropdown .dropdown-icon {
    position: absolute;
    right: 15px;
}

.dropdown-icon {
    --size: 15px;
    --color: var(--txt-color);
    height: var(--size);
    width: var(--size);
    position: relative;
    cursor: pointer;
}

.dropdown-icon::after,
.dropdown-icon::before {
    --height: 2px;
    content: "";
    width: 50%;
    height: var(--height);
    border-radius: 2.5px;
    position: absolute;
    top: calc(50% - var(--height) / 2);
    background-color: var(--color);
    transition: transform 0.3s ease;
}

.dropdown-icon::before {
    left: calc(50% - var(--height) / 3);
    transform-origin: center left;
    transform: rotate(-45deg);
}

.dropdown-icon::after {
    right: calc(50% - var(--height) / 3);
    transform-origin: right;
    transform: rotate(45deg);
}

.dropdown-icon.active::before {
    transform: rotate(45deg);
}

.dropdown-icon.active::after {
    transform: rotate(-45deg);
}

.sidebar-menu > li > a:hover > .dropdown-icon {
    --color: var(--main-color);
}

.sidebar-menu-dropdown-content {
    padding: 0;
    height: 0;
    overflow: hidden;
    transition: height 0.25s ease-in-out;
}

.sidebar-menu-dropdown-content > li > a {
    padding-left: 50px;
}

.darkmode-toggle {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.darkmode-switch {
    --height: var(--darkmode-toggle-size);
    --width: calc(var(--height) * 2);
    height: var(--height);
    width: var(--width);
    border-radius: calc(var(--height) / 2);
    background-color: #f5f6fa;
    position: relative;
}

.darkmode-switch::before {
    content: "";
    --height: 20px;
    --main-color: #0652dd;
    width: var(--height);
    height: var(--height);
    position: absolute;
    left: 0;
    border-radius: 50%;
    background-color: var(--main-color);
    transition: left 0.3s ease-in-out;
}

.darkmode-switch.active::before {
    left: 50%;
}