/* .theme-mode-light {
    --main-bg: var(--main-bg-light);
    --second-bg: var(--second-bg-light);
    --txt-color: var(--txt-color-light);
    --box-shadow: var(--box-shadow-light);
}

.theme-mode-dark {
    --main-bg: var(--main-bg-dark);
    --second-bg: var(--second-bg-dark);
    --txt-color: var(--txt-color-dark);
    --box-shadow: var(--box-shadow-dark);
} */

.dark {
    --main-bg: #2d2d2d;
    --txt-color: #bbb;
    --second-bg: #2d2d2d;
    --box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 10px;
}

.dark .ant-input::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #bbb;
  }