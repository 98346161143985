
/* Pipe Table */

.pipetable {
    border-collapse: collapse !important;
    min-width: 50%;
    max-width: 90%;
    overflow: auto;
    margin: 20px;
  }
  
  .pipetable td, th {
    border: 1px solid #E1E1E1;
    padding: 5px;
  }
  
  .pipetable th {
    /* padding-top: 12px;
    padding-bottom: 12px; */
    text-align: left;
    background-color:#3886BE;
    color: white;
  }
  
  /* .pipetable tbody tr:nth-child(even){background-color: #E7E9EB;}
  
  .pipetable tbody tr:hover {background-color: red;} */
  
  /* .pipetable tbody tr:nth-child(odd) {
    background: #eee;
  } */
  