/* .ant-checkbox .ant-checkbox-inner {
    border-color: #8764B8 !important;
}

.ant-checkbox-checked .ant-checkbox-inner {
    background-color: #8764B8 !important;
} */

.ant-form-item {
    margin-bottom: 0px;
}

.ant-form-item label,
.ant-form-item .ant-form-item-label>label {
    color: var(--txt-color);
    font-size: 16px;
    font-weight: 400;
}

.ant-form-item-with-help .ant-form-item-explain {
    position: absolute;
}

.ant-card {
    box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%) !important;
}

.ant-avatar {
    border: none !important;
}

.ant-divider-horizontal {
    margin: 0;
}

.ant-drawer-close {
    display: none !important;
}

/* .ant-table-wrapper .ant-table-tbody>tr>td {
    padding: 10px 10px !important;
} */

/* .table {
    box-shadow: 0 2px 10px -1px rgb(69 90 100 / 30%);
} */

.ant-btn {
    border-radius: 4px !important;
}

.ant-btn-primary {
    background-color: #40A9FF !important;
}

.ant-btn-primary:hover {
    background-color: #707070 !important;
}

.ant-btn-link:not([disabled]) {
    color: #40A9FF !important;
}

.customIcon {
    color: #313131 !important;
}

.customIcon:hover {
    color: #40a9ff !important;
}

.table thead th {
    border-bottom: 1px solid #e2e5e8;
    font-size: 13px;
    color: #37474f;
    /* background: #ecf0f5 !important; */
    text-transform: uppercase;
}

.table td,
.table th {
    border-top: 1px solid #e2e5e8;
    white-space: nowrap;
    padding: 1.05rem 0.75rem;
}

.ant-card-body {
    padding: 14px !important;
}

.ant-upload-drag {
    border: 2px dashed #d9d9d9 !important;
}

.ant-upload-drag:hover {
    border: 2px dashed #1890ff !important;
}

.dark .ant-input,
.dark .ant-input-password {
    background-color: var(--main-bg);
    color: var(--txt-color);
    border-color: darkslategrey;
}

.dark .ant-input-password-icon {
    color: var(--txt-color) !important;
}

.dark .ant-select-selector,
.dark .ant-select-arrow {
    background-color: var(--main-bg) !important;
    color: var(--txt-color) !important;
    border-color: darkslategrey !important;
}

.dark .ant-table {
    color: var(--txt-color);
}

.dark .ant-table-thead>tr>th {
    background: var(--main-bg);
    color: var(--txt-color);
}

.dark .ant-table-tbody>tr>td {
    background: var(--main-bg);
    color: var(--txt-color);
}

/* Apply font family to specific Ant Design components */
.ant-typography,
/* .ant-btn, */
.ant-input,
.ant-select,
.ant-select-selector,
.ant-checkbox,
.ant-radio,
.ant-select-selection,
.ant-select-item,
.ant-menu,
.ant-dropdown-menu,
.ant-modal-content {
    font-family: 'Diodrum-Regular';
    /* Apply font family to all Ant Design components */
    color: #313131 !important;
}