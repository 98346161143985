body {
  margin: 0;
  font-family: 'Diodrum-Regular';
    overflow: hidden;
    color: #313131 !important;
}

@font-face {
  font-family: 'Diodrum-Regular';
  font-style: normal;
  font-weight: 400;
  src: local('Diodrum-Regular'),
    url('fonts/Diodrum-Regular.otf') format('opentype');
}

@font-face {
  font-family: 'Diodrum-Semibold';
  font-style: normal;
  font-weight: 600;
  src: local('Diodrum-Semibold'),
    url('fonts/Diodrum-Semibold.otf') format('opentype');
}

::-webkit-scrollbar {
  width: 8px;
  height: 5px;
  position: relative;
  margin:5px;
} 

::-webkit-scrollbar-thumb {
 background: #349eff;
 width: 5px;
  height: 5px;
  border-radius: 5px;
}   


.scrollview::-webkit-scrollbar-button:single-button {
  
  display: block;
  background-size: 10px;
  background-repeat: no-repeat;
}

/* Up */
.scrollview::-webkit-scrollbar-button:single-button:vertical:decrement {
  border-radius: 5px 5px 0 0;
  height: 16px;
  width: 16px;
  background-position: center 4px;
  /* background-color: #E1E1E1; */
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='50,00 0,50 100,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:vertical:decrement:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='50,00 0,50 100,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:vertical:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='50,00 0,50 100,50'/></svg>");
}

/* Down */
.scrollview::-webkit-scrollbar-button:single-button:vertical:increment {
  border-radius: 0px 0px 5px 5px;
  height: 16px;
  width: 16px;
  background-position: center 4px;
  /* background-color: #E1E1E1; */
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 100,0 50,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:vertical:increment:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 100,0 50,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:vertical:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 100,0 50,50'/></svg>");
}

/* Left */
.scrollview::-webkit-scrollbar-button:single-button:horizontal:decrement {
  border-radius: 5px 0px 0 5px;
  height: 12px;
  width: 12px;
  background-position: 3px 3px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,50 50,100 50,0'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:horizontal:decrement:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,50 50,100 50,0'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:horizontal:decrement:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,50 50,100 50,0'/></svg>");
}

/* Right */
.scrollview::-webkit-scrollbar-button:single-button:horizontal:increment {
  border-radius: 0 5px 5px 0;
  height: 12px;
  width: 12px;
  background-position: 3px 3px;
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 0,100 50,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:horizontal:increment:hover {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 0,100 50,50'/></svg>");
}

.scrollview::-webkit-scrollbar-button:single-button:horizontal:increment:active {
  background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' width='100' height='100' fill='rgb(170, 170, 170)'><polygon points='0,0 0,100 50,50'/></svg>");
}

a {
  color: #1677ff !important;
}

@import url("./styles/theme.css");
@import url("./styles/layout.css");
@import url("./styles/header.css");
@import url("./styles/sidebar.css");
@import url("./styles/antd.css");
@import url("./styles/chatGPT.css");
@import url("./styles/smartChat.css");