.chat-toggle-btn {
  position: fixed;
  bottom: 35px;
  right: 0px;
  background-color: #E4001C;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
  border-top-left-radius: 25px;
  border-bottom-left-radius: 25px;
  display: block;
  z-index: 1;
  height: 30px;
}

/* .chat-window {
  width: fit-content;
  position: absolute;
  margin: 0px 8px 8px 8px;
} */

/* .chat-window {
  margin: 0px 8px 8px 8px;
  position: fixed;
} */

.chat-window {
  position: fixed;
}

.inner-chat-window {
  display: flex;
  flex-direction: column;
  height: 524px;
  overflow: hidden;
  min-height: 547px;
  min-width: 371px;
  background: #fff;
  box-shadow: rgba(0, 0, 0, 0.133) 0px 3.2px 7.2px 0px, rgba(0, 0, 0, 0.11) 0px 0.6px 1.8px 0px;
  border: 1px solid #ccc;
  height: 97vh;
}

/* .inner-chat-window {
  display: flex;
  flex-direction: column;
  height: 524px;
  overflow: hidden;
  width: 371px;
   border:1px solid #ccc;
} */

.chat-nav {
  background-color: #E4001C;
  position: relative;
  height: 37px;
  min-height: 37px;
  width: 100%;
  display: block;
  box-shadow: 0px 3px 6px #00000029;
}

.myRed-link {
  margin-left: 14px;
  /* margin-top: 14px; */
  font-size: 12px;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;
  color: #fff !important;
  position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);
}

.myRed-link:hover {
  color: #fff;
}

.page-tabs-div {
  padding: 0px 5px;

}

.pageTabs {
  text-align: center;
  font-family: 'Diodrum-Medium';
  padding-top: 7px;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
  display: inline-flex;
  margin-left: 0px;
  list-style-type: none;
  margin-block-start: 0px;
  margin-block-end: 0px;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  padding-inline-start: 0px;
}

.pageTab {
  width: fit-content;
  /* margin-right: 8px; */
  font-size: 16px;
  /* min-width: 120px; */
  height: 35px;
  cursor: pointer;
  padding: 10px 20px;
  box-sizing: border-box;
  border-radius: 4px 4px 0px 0px;
  margin-left: 2px;
}

.activePageTab {
  color: white;
  font-family: 'Diodrum-Semibold';
  background-color: #8764B8;

}

.procTab {
  color: white;
  font-family: 'Diodrum-Semibold';
  background-color: #349eff;

}

.opexTab {
  color: white;
  font-family: 'Diodrum-Semibold';
  background-color: #E4001C;

}

.chat-header {
  color: white;
  text-align: right;
  right: 0;
  font-size: 20px;
  display: inline;
  margin: 0px;
  margin-right: 12px;
  position: absolute;
    top: 50%;
    transform: translate(0, -50%);
}

.chat-header>a {
  color: #fff !important;
}

.image-bubble {
  background-color: #8764B8;
  width: 28px;
  height: 28px;
  border-radius: 100%;
  text-align: center;
}

.chat-messages {
  flex: 1;
  margin: 10px 10px 10px 10px;
  overflow-y: auto;
}

.message {
  /* margin: 10px; */
  padding: 8px 12px;
  border-radius: 5px;
  word-wrap: break-word;
}

.received {
  background-color: #F3F3F3;
  max-width: 245px;
  box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
}

.sent {
  background-color: #007bff;
  color: white;
  align-self: flex-end;
}

.chat-input {
  display: flex;
  align-items: center;
  /* background-color: red; */
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  min-height: 45px;
  max-height: 56px;
  font-family: 'Diodrum-Regular';
  font-size: 15px;
  padding-left: 21px;
}

.chat-input input {
  flex: 1;
  font-family: 'Diodrum-Regular';
  align-items: center;
  max-height: 47px;
  margin-right: 10px;
}

.chat-input .button {
  cursor: pointer;
  color: #313131;
  margin-right: 10px;
  margin-bottom: 5px;
  font-size: 20px;
  padding: 0px;
}

.chat-input .button:hover {
  color: #40a9ff;
}

.chat-feedback {
  text-align: left;
  display: block;
  height: 37px;
}

.chat-feedback button {
  text-decoration: none;
  color: #40a9ff;
  font-size: 15px;
  margin: 2px 0px 0px 25px;
  display: inline-block;
}

.ant-tag {
  font-size: 15px;
  font-family: 'Diodrum-Regular';
  border-radius: 25px;
  padding: 8px 12px 6px 12px;
  border-color: #8764B8;
  background-color: unset;
}

.ant-tag:hover {
  font-size: 15px;
  font-family: 'Diodrum-Regular';
  border-radius: 25px;
  padding: 8px 12px 6px 12px;
  color: white;
  border-color: #8764B8;
  background-color: #8764B8;
}

.triangle {
  width: 0;
  height: 0;
  border-left: 6px solid transparent;
  border-right: 6px solid transparent;
  border-bottom: 9px solid #D9D9D9;
  margin: auto;
}

.chatgpt .message {
  background-color: #F3F3F3;
  /* var(--main-bg); */
  padding: 10px;
  box-shadow: var(--box-shadow);
  /* max-width: 245px; */
  margin-bottom: 5px;
  padding-left: 15px;
  padding-right: 15px;
}

.chatgpt .question {
  margin-right: 10px;
  min-width: 30%;
  max-width: 245px;
  line-height: 0;
  /* text-align: justify;
    text-justify: inter-word; */
}

.chatgpt .answer {
  /* var(--main-bg); */
  margin-top: 5px;
  margin-bottom: 10px;
  /* width: 95% !important; */
  /* text-align: justify;
    text-justify: inter-word; */
  line-height: 1.5;
  /* text-align: justify; */
  color: #313131 !important;
  font-size: 15px; 
}